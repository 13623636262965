.links-main {
  margin-left: auto;
  word-spacing: 5px;
}

.about-links {
  text-decoration: none;
  color: white;
  font-size: 20px;
}
.service-links {
  text-decoration: none;
  color: white;
  font-size: 20px;
}
.product-links {
  text-decoration: none;
  color: white;
  font-size: 20px;
}
.drawer-icon {
  margin-left: auto;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .navbarImage {
    height: 50px;
    margin-top: 5px;
  }
}

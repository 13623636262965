.aboutUs-heading {
  text-align: initial;
  color: white;
  font-size: 45px;
  font-weight: 600;
  background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 90%;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1.5;
}
.aboutUsvrlines {
  color: #2378e9;
  background: #2378e9;
  width: 10px;
  height: 150px;
}
.aboutUs-Firstimage {
  width: 100%;
  margin-top: 40px;
}
.aboutUs-content {
  font-size: 25px;
  color: white;
  line-height: 2;
}
.aboutUs-Secondimage {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.focus-main {
  margin-top: 40px;
  margin-bottom: 40px;
}
.smallhrline {
  color: #2378e9;
  background: #2378e9;
  width: 30px;
  height: 2px;
  margin-top: 40px;
}
.focusedonsectionauxtomate {
  color: white;
  font-size: 25px;
}
.focusedonheading {
  color: white;
  font-size: 25px;
  font-weight: 600;
}

.focushrlines {
  color: #2378e9;
  background: #2378e9;
  width: 10px;
  height: 270px;
}
.focus-icon {
  font-size: 40px !important;
}
.focussectioncontent {
  color: white;
  font-size: 25px;
  line-height: 2;
}
.parentCarousalContainer {
  width: 100%;
  background-color: white;
  height: 300px;
}
.client-testimonials-heading {
  font-size: 30px;
  font-weight: 600;
  justify-content: center;
  display: flex;
}
.hrlineaboutus {
  color: #2378e9;
  background: #2378e9;
  width: 10px;
  margin-top: 30px;
  height: 220px;
}
.CardCarousel {
  height: 270px;
}
.worldEducation {
  font-size: 30px;
  font-weight: 800;
}
.worldEducation-content {
  font-size: 25px;
  line-height: 1.25;
  width: 70%;
}
.aboutusbussinessoutcome-main {
  display: flex;
}
.aboutusbussinessoutcome-heading {
  text-align: initial;
  color: white;
  font-size: 50px;
  font-weight: 600;
  background-image: linear-gradient(to bottom right, #e4cfcf, #fcf113);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 75%;
  margin-top: 50px;
  margin-bottom: 10px;
  line-height: 1.5;
}
.aboutusimageone {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 25px;
}
.aboutusimagesecond {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 25px;
}
.slick-next {
  right: 0px;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .aboutUs-heading {
    text-align: initial;
    color: white;
    font-size: 20px;
    font-weight: 600;
    background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 1.5;
  }
  .aboutUsvrlines {
    color: #2378e9;
    background: #2378e9;
    width: 3px;
    height: 110px;
  }
  .smallhrline {
    color: #2378e9;
    background: #2378e9;
    width: 20px;
    height: 2px;
    margin-top: 30px;
}
  .aboutUs-Firstimage {
    width: 100%;
    margin-top: 20px;
  }
  .aboutUs-content {
    font-size: 20px;
    color: white;
    line-height: 1.5;
  }
  .focussectioncontent {
    color: white;
    width: 100%;
    font-size: 20px;
    line-height: 1.5;
  }
  .focus-icon {
    font-size: 25px !important;
  }
  .focushrlines {
    color: #2378e9;
    background: #2378e9;
    width: 4px;
    height: 455px;
    margin-left: 10px;
  }
  .focusedonsectionauxtomate {
    color: white;
    font-size: 18px;
  }
  .focusedonheading {
    color: white;
    font-size: 13px;
    font-weight: 600;
  }
  .AccordianStyle {
    margin-top: 25px;
  }
  .client-testimonials-heading {
    font-size: 16px;
    font-weight: 600;
    margin-top: 30px;
    display: flex;
  }
  .hrlineaboutus {
    color: #2378e9;
    background: #2378e9;
    width: 5px;
    margin-top: 30px;
    height: 230px;
  }
  .worldEducation {
    font-size: 20px;
    font-weight: 800;
    margin-top: 30px;
  }
  .worldEducation-content {
    font-size: 15px;
    line-height: 1.25;
    width: 100%;
  }
  .aboutusbussinessoutcome-heading {
    text-align: initial;
    color: white;
    font-size: 25px;
    font-weight: 600;
    background-image: linear-gradient(to bottom right, #e4cfcf, #fcf113);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 10px;
    line-height: 1.5;
  }
  .aboutusimageone {
    width: 100%;
    height: -webkit-fill-available;
    margin-bottom: 10px;
    margin-top: 25px;
  }
  .aboutusimagesecond {
    width: 100%;
    height: -webkit-fill-available;
    margin-bottom: 10px;
    margin-top: 25px;
  }
  .slick-next {
    right: 0px !important;
}
}

.talktoUsLandingSection {
  text-align: initial;
  color: white;
  font-size: 50px;
  font-weight: 600;
  background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 75% !important;
}
.talktoUsvrlines {
  color: #2378e9;
  background: #2378e9;
  width: 10px;
  height: 200px;
  margin-bottom: 50px;
}
.talktoUsSmallLine {
  color: #2378e9;
  background: #2378e9;
  width: 30px;
  margin-top: 40px;
  height: 5px;
}
.talktoUslandingSubSection {
  color: white;
  width: 88%;
  line-height: 2;
  margin-top: 15px;
  font-size: 25px;
  text-align: initial;
}

.textarea-talk-main{
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 10px;
}
.textfield-talk-main {
  text-align: center;
}
textarea#message {
  color: white;
  background: #252525;
  border-color: #2378e9 !important;
}
::placeholder {
  color: white !important;
  opacity: 1;
}
.talktoUsSubmit-btn {
  width: 20%;
  margin-top: 50px !important;
  font-size: 20px !important;
  border-color: #2378e9 !important;
  color: white !important;
}
.css-1ptx2yq-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 1px solid rgba(25, 118, 210, 1) !important;
}
.css-a88p61-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 1px solid rgba(25, 118, 210, 1) !important;
}
.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
  color: rgba(25, 118, 210, 1) !important ;
}
.css-ghsjzk-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 1px solid rgba(25, 118, 210, 1) !important;
}
.css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
  color: rgba(25, 118, 210, 1) !important ;
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  font-size: 20px !important;
  color: white !important;
}

.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
  color: white !important;
}
.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
  color: white !important;
  font-size: 20px !important;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .talktoUsLandingSection {
    text-align: initial;
    color: white;
    font-size: 23px;
    font-weight: 600;
    background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100% !important;
  }
  .talktoUsvrlines {
    color: #2378e9;
    background: #2378e9;
    width: 10px;
    height: 110px;
    margin-bottom: 50px;
  }
  .talktoUsSmallLine {
    color: #2378e9;
    background: #2378e9;
    width: 20px;
    margin-top: 35px;
    height: 3px;
  }
  .talktoUslandingSubSection {
    color: white;
    width: 100%;
    margin-top: 15px;
    font-size: 20px;
    text-align: center;
  }
  .textfield {
    width: 90% !important;
  }
  .textarea {
    width: 90% !important;
  }
  textarea#message {
    color: white;
    background: #252525;
  }
  
  .talktoUsSubmit-btn {
    width: 40%;
    margin-top: 25px !important;
    font-size: 15px !important;
    border-color: rgba(25, 118, 210, 1) !important;
    color: white !important;
  }
}

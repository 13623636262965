.serviceLandingSection {
  text-align: initial;
  color: white;
  font-size: 50px;
  font-weight: 600;
  background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 65%;
}
.servicevrlines {
  color: #2378e9;
  background: #2378e9;
  width: 10px;
  height: 330px;
  margin-bottom: 50px;
}
.serviceSmallLine {
  color: #2378e9;
  background: #2378e9;
  width: 30px;
  margin-top: 60px;
  height: 5px;
}
.servicelandingSubSection {
  color: white;
  width: 88%;
  line-height: 2;
  margin-top: 15px;
  font-size: 25px;
  text-align: initial;
}
.serviceImageone {
  width: 100%;
  margin-top: 30px;
}
.uicontent {
  margin-top: 40px;
  margin-bottom: 40px;
  color: white;
  font-size: 25px;
  line-height: 2;
  width: 77%;
}
.mobility-heading {
  margin-top: 40px;
}
.service-mobility-LandingSection {
  text-align: initial;
  color: white;
  font-size: 50px;
  font-weight: 600;
  background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 65% !important;
}
.service-mobility-vrlines {
  color: #2378e9;
  background: #2378e9;
  width: 10px;
  height: 330px;
  margin-bottom: 50px;
}
.service-mobility-SmallLine {
  color: #2378e9;
  background: #2378e9;
  width: 30px;
  margin-top: 60px;
  height: 5px;
}
.service-mobility-landingSubSection {
  color: white;
  width: 88%;
  line-height: 2;
  margin-top: 15px;
  font-size: 25px;
  text-align: initial;
}
.mobility-serviceImageone {
  width: 100%;
  margin-top: 30px;
}
.mobility-uicontent {
  margin-top: 40px;
  margin-bottom: 40px;
  color: white;
  font-size: 25px;
  line-height: 2;
  width: 77%;
}

.apiIntegration-LandingSection {
  text-align: initial;
  color: white;
  font-size: 50px;
  font-weight: 600;
  background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 65% !important;
}
.apiIntegration-vrlines {
  color: #2378e9;
  background: #2378e9;
  width: 10px;
  height: 200px;
  margin-bottom: 50px;
}
.apiIntegration-SmallLine {
  color: #2378e9;
  background: #2378e9;
  width: 30px;
  margin-top: 60px;
  height: 5px;
}
.apiIntegration-landingSubSection {
  color: white;
  width: 88%;
  line-height: 2;
  margin-top: 15px;
  font-size: 25px;
  text-align: initial;
}
.apiIntegration-Imageone {
  width: 100%;
  margin-top: 30px;
}
.apiIntegration-uicontent {
  margin-top: 40px;
  margin-bottom: 40px;
  color: white;
  font-size: 25px;
  line-height: 2;
  width: 77%;
}
.apiTesting-LandingSection {
  text-align: initial;
  color: white;
  font-size: 50px;
  font-weight: 600;
  background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 65% !important;
}
.apiTesting-vrlines {
  color: #2378e9;
  background: #2378e9;
  width: 10px;
  height: 200px;
  margin-bottom: 50px;
}
.apiTesting-SmallLine {
  color: #2378e9;
  background: #2378e9;
  width: 30px;
  margin-top: 60px;
  height: 5px;
}
.apiTesting-landingSubSection {
  color: white;
  width: 88%;
  line-height: 2;
  margin-top: 15px;
  font-size: 25px;
  text-align: initial;
}
.apiTesting-Imageone {
  width: 100%;
  margin-top: 30px;
}
.apiTesting-uicontent {
  margin-top: 40px;
  margin-bottom: 40px;
  color: white;
  font-size: 25px;
  line-height: 2;
  width: 77%;
}

.productNServiceDesign-LandingSection {
  text-align: initial;
  color: white;
  font-size: 50px;
  font-weight: 600;
  background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 65% !important;
}
.productNServiceDesign-vrlines {
  color: #2378e9;
  background: #2378e9;
  width: 10px;
  height: 200px;
  margin-bottom: 50px;
}
.productNServiceDesign-SmallLine {
  color: #2378e9;
  background: #2378e9;
  width: 30px;
  margin-top: 60px;
  height: 5px;
}
.productNServiceDesign-landingSubSection {
  color: white;
  width: 88%;
  line-height: 2;
  margin-top: 15px;
  font-size: 25px;
  text-align: initial;
}
.productNServiceDesign-Imageone {
  width: 100%;
  margin-top: 30px;
}
.productNServiceDesign-uicontent {
  margin-top: 40px;
  margin-bottom: 40px;
  color: white;
  font-size: 25px;
  line-height: 2;
  width: 77%;
}

.enterpriseNBussiness-LandingSection {
  text-align: initial;
  color: white;
  font-size: 50px;
  font-weight: 600;
  background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 79% !important;
}
.enterpriseNBussiness-vrlines {
  color: #2378e9;
  background: #2378e9;
  width: 10px;
  height: 200px;
  margin-bottom: 50px;
}
.enterpriseNBussiness-SmallLine {
  color: #2378e9;
  background: #2378e9;
  width: 30px;
  margin-top: 60px;
  height: 5px;
}
.enterpriseNBussiness-landingSubSection {
  color: white;
  width: 88%;
  line-height: 2;
  margin-top: 15px;
  font-size: 25px;
  text-align: initial;
}
.enterpriseNBussiness-Imageone {
  width: 100%;
  margin-top: 30px;
}
.enterpriseNBussiness-uicontent {
  margin-top: 40px;
  margin-bottom: 40px;
  color: white;
  font-size: 25px;
  line-height: 2;
  width: 77%;
}

.getstarted {
  color: white;
  font-size: 50px;
  font-weight: 600;
  line-height: 70px;
  margin-top: 68px !important;
  background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.serviceGetStartedButton {
  margin-top: 20px;
  width: 25%;
  position: relative;
  height: 45px;
  background-color: #252525;
  color: white;
  font-family: Roboto;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 50px;
  word-spacing: 5px;
  border-radius: 5px;
  border: 2px solid #007bff;
}
.getStartedContent {
  font-size: 25px;
  color: white;
  width: 54%;
  line-height: 2;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .serviceLandingSection {
    text-align: initial;
    color: white;
    font-size: 35px;
    font-weight: 600;
    background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100%;
  }
  .servicevrlines {
    color: #2378e9;
    background: #2378e9;
    width: 3px;
    height: 350px;
    margin-bottom: 50px;
  }
  .serviceSmallLine {
    color: #2378e9;
    background: #2378e9;
    width: 25px;
    margin-left: -10px;
    margin-top: 35px;
    height: 3px;
  }
  .servicelandingSubSection {
    color: white;
    width: 100%;
    line-height: 1.5;
    margin-top: 15px;
    font-size: 20px;
    text-align: initial;
  }
  .serviceImageone {
    width: 100%;
    margin-top: 30px;
  }
  .uicontent {
    margin-top: 40px;
    margin-bottom: 40px;
    color: white;
    font-size: 20px;
    line-height: 1.5;
    width: 100%;
  }
  .mobility-heading {
    margin-top: 40px;
  }
  .service-mobility-LandingSection {
    text-align: initial;
    color: white;
    font-size: 35px;
    font-weight: 600;
    background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100%;
  }
  .service-mobility-vrlines {
    color: #2378e9;
    background: #2378e9;
    width: 3px;
    height: 380px;
    margin-bottom: 30px;
  }
  .service-mobility-SmallLine {
    color: #2378e9;
    background: #2378e9;
    width: 20px;
    margin-top: 30px;
    height: 3px;
  }
  .service-mobility-landingSubSection {
    color: white;
    width: 100%;
    line-height: 1.5;
    margin-top: 15px;
    font-size: 20px;
    text-align: initial;
  }
  .mobility-serviceImageone {
    width: 100%;
    margin-top: 30px;
  }
  .mobility-uicontent {
    margin-top: 40px;
    margin-bottom: 40px;
    color: white;
    font-size: 20px;
    line-height: 1.5;
    width: 100%;
  }

  .apiIntegration-LandingSection {
    text-align: initial;
    color: white;
    font-size: 25px;
    font-weight: 600;
    background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100%;
  }
  .apiIntegration-vrlines {
    color: #2378e9;
    background: #2378e9;
    width: 3px;
    height: 250px;
    margin-bottom: 30px;
  }
  .apiIntegration-SmallLine {
    color: #2378e9;
    background: #2378e9;
    width: 20px;
    margin-top: 35px;
    height: 3px;
  }
  .apiIntegration-landingSubSection {
    color: white;
    width: 100%;
    line-height: 1.5;
    margin-top: 15px;
    font-size: 20px;
    text-align: initial;
  }
  .apiIntegration-Imageone {
    width: 100%;
    margin-top: 30px;
  }
  .apiIntegration-uicontent {
    margin-top: 40px;
    margin-bottom: 40px;
    color: white;
    font-size: 20px;
    line-height: 1.5;
    width: 100%;
  }
  .apiTesting-LandingSection {
    text-align: initial;
    color: white;
    font-size: 25px;
    font-weight: 600;
    background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100%;
  }
  .apiTesting-vrlines {
    color: #2378e9;
    background: #2378e9;
    width: 3px;
    height: 250px;
    margin-bottom: 30px;
  }
  .apiTesting-SmallLine {
    color: #2378e9;
    background: #2378e9;
    width: 20px;
    margin-top: 35px;
    height: 3px;
  }
  .apiTesting-landingSubSection {
    color: white;
    width: 100%;
    line-height: 1.5;
    margin-top: 15px;
    font-size: 20px;
    text-align: initial;
  }
  .apiTesting-Imageone {
    width: 100%;
    margin-top: 30px;
  }
  .apiTesting-uicontent {
    margin-top: 40px;
    margin-bottom: 40px;
    color: white;
    font-size: 20px;
    line-height: 1.5;
    width: 100%;
  }

  .productNServiceDesign-LandingSection {
    text-align: initial;
    color: white;
    font-size: 25px;
    font-weight: 600;
    background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100%;
  }
  .productNServiceDesign-vrlines {
    color: #2378e9;
    background: #2378e9;
    width: 3px;
    height: 400px;
    margin-bottom: 30px;
  }
  .productNServiceDesign-SmallLine {
    color: #2378e9;
    background: #2378e9;
    width: 20px;
    margin-top: 30px;
    height: 3px;
  }
  .productNServiceDesign-landingSubSection {
    color: white;
    width: 100%;
    line-height: 1.5;
    margin-top: 15px;
    font-size: 20px;
    text-align: initial;
  }
  .productNServiceDesign-Imageone {
    width: 100%;
    margin-top: 30px;
  }
  .productNServiceDesign-uicontent {
    margin-top: 40px;
    margin-bottom: 40px;
    color: white;
    font-size: 20px;
    line-height: 1.5;
    width: 100%;
  }

  .enterpriseNBussiness-LandingSection {
    text-align: initial;
    color: white;
    font-size: 25px;
    font-weight: 600;
    background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100% !important;
  }
  .enterpriseNBussiness-vrlines {
    color: #2378e9;
    background: #2378e9;
    width: 3px;
    height: 250px;
    margin-bottom: 30px;
  }
  .enterpriseNBussiness-SmallLine {
    color: #2378e9;
    background: #2378e9;
    width: 20px;
    margin-top: 30px;
    height: 3px;
  }
  .enterpriseNBussiness-landingSubSection {
    color: white;
    width: 100%;
    line-height: 1.5;
    margin-top: 15px;
    font-size: 20px;
    text-align: initial;
  }
  .enterpriseNBussiness-Imageone {
    width: 100%;
    margin-top: 30px;
  }
  .enterpriseNBussiness-uicontent {
    margin-top: 40px;
    color: white;
    font-size: 20px;
    line-height: 1.5;
    width: 100%;
  }

  .getstarted {
    color: white;
    font-size: 35px;
    font-weight: 600;
    line-height: 70px;
    margin-top: 40px !important;
    background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .serviceGetStartedButton {
    margin-top: 20px;
    width: 100%;
    position: relative;
    height: 45px;
    background-color: #252525;
    color: white;
    font-family: Roboto;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 50px;
    word-spacing: 5px;
    border-radius: 5px;
    border: 2px solid #007bff;
  }
  .getStartedContent {
    font-size: 20px;
    color: white;
    width: 100%;
    line-height: 1.5;
  }
}

.productLandingSection {
  text-align: initial;
  color: white;
  font-size: 50px;
  font-weight: 600;
  background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 65% !important;
}
.productvrlines {
  color: #2378e9;
  background: #2378e9;
  width: 10px;
  height: 330px;
  margin-bottom: 50px;
}
.productSmallLine {
  color: #2378e9;
  background: #2378e9;
  width: 30px;
  margin-top: 60px;
  height: 5px;
}
.productlandingSubSection {
  color: white;
  width: 88%;
  line-height: 2;
  margin-top: 15px;
  font-size: 25px;
  text-align: initial;
}
.productVideo {
  width: 100%;
  height: 700px;
}
.videoDescription {
  width: 95%;
  color: white;
  font-size: 25px;
  display: flex;
  text-align: justify;
  line-height: 1.75;
}
.productButton-main {
  text-align: start;
}
.productButton {
  margin-left: 0px;
  margin-top: 20px;
  width: 22%;
  position: relative;
  height: 45px;
  background-color: #252525;
  color: white;
  font-family: Roboto;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 50px;
  word-spacing: 5px;
  border-radius: 5px;
  border: 2px solid #007bff;
}

.productFirstImage {
  width: 100%;
}
.firstImageDescription {
  color: white;
  font-size: 25px;
  margin-top: 100px;
  line-height: 2;
  text-align: initial;
  margin-bottom: 50px;
}
.qualityGrid-main {
  text-align: initial;
}
.ProductGridTitle {
  color: white;
  font-size: 30px;
  text-align: initial;
}
.ProductGrid {
  color: white;
  font-size: 20px;
  line-height: 2;
  width: 300px;
}
.apinatorProductHeading {
  color: white;
  font-size: 30px;
  display: flex;
  text-align: initial;
}
.apinatorform-Heading {
  margin-top: 50px;
  color: white;
  font-size: 33px;
  display: block;
  text-align: end;
}
.apinatorform-x-tHeading {
  color: white;
  font-size: 33px;
  display: flex;
  text-align: initial;
  margin-top: 30px;
}
.apinatorworkflow-Heading {
  margin-top: 50px;
  color: white;
  font-size: 30px;
  display: block;
  text-align: end;
}
.apinatorProductContent {
  color: white;
  font-size: 25px;
  width: 70%;
  line-height: 2;
  text-align: initial;
  margin-top: 15px;
}
.apinatorProductContentMain {
  display: flex !important;
  column-gap: 13%;
}

.apinatorProductContent-Workflow {
  color: white;
  font-size: 25px;
  width: 77%;
  line-height: 2;
  text-align: initial;
  margin-top: 15px;
}

/* left product */
.apinatorProductContentMainLeft {
  display: flex !important;
  column-gap: 13%;
  flex-direction: row-reverse;
}

.productImageRight {
  width: 80%;
  border-radius: 30px;
  margin-bottom: 25px;
}
.productImageLeft {
  width: 100%;
  border-radius: 30px;
  margin-bottom: 50px;
  margin-top: -1px;
}
.getStarted-heading {
  text-align: initial;
  color: white;
  font-size: 50px;
  font-weight: 600;
  background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.getstartedContent {
  text-align: initial;
  color: white;
  font-size: 22px;
  line-height: 2;
  width: 80%;
}
.productGetStarted-image {
  width: 100%;
  height: 72%;
  margin-top: 50px;
}
.productAccordianStyle {
  margin-top: 50px;
  text-align: initial;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .productvrlines {
    color: #2378e9;
    background: #2378e9;
    width: 5px;
    height: 350px;
    margin-bottom: 50px;
  }
  .productSmallLine {
    color: #2378e9;
    background: #2378e9;
    width: 20px;
    margin-top: 32px;
    height: 3px;
  }
  .productLandingSection {
    text-align: initial;
    color: white;
    font-size: 25px;
    font-weight: 600;
    background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100% !important;
  }
  .productlandingSubSection {
    color: white;
    width: 100%;
    line-height: 1.5;
    margin-top: 15px;
    font-size: 20px;
    text-align: initial;
  }
  .productVideo {
    width: 100%;
    height: 700px;
  }
  .videoDescription {
    width: 98%;
    color: white;
    font-size: 20px;
    display: flex;
    text-align: initial;
    line-height: 1.5;
  }

  .productButton {
    margin-left: 0px;
    margin-top: 20px;
    width: 85%;
    position: relative;
    height: 45px;
    background-color: #252525;
    color: white;
    font-family: Roboto;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 50px;
    word-spacing: 5px;
    border-radius: 5px;
    border: 2px solid #007bff;
  }

  .productFirstImage {
    width: 100%;
  }
  .firstImageDescription {
    color: white;
    font-size: 20px;
    margin-top: 50px;
    line-height: 1.5;
    text-align: initial;
    margin-bottom: 50px;
  }
  .ProductGridTitle {
    color: white;
    font-size: 25px;
    text-align: initial;
  }
  .ProductGrid {
    color: white;
    font-size: 20px;
    line-height: 1.5;
    width: 98%;
  }
  .qualityGrid-icons {
    font-size: 35px !important;
  }
  .apinatorProductHeading {
    color: white;
    font-size: 24px;
    display: flex;
    text-align: initial;
  }
  .apinatorProductContent {
    margin-top: 0px;
    color: white;
    font-size: 20px;
    width: 100%;
    line-height: 1.5;
    text-align: initial;
  }
  .productImageRight {
    width: 100%;
    height: 160px;
    border-radius: 20px;
    margin-top: 30px;
  }
  .productImageLeft {
    width: 100%;
    height: 150px;
    border-radius: 20px;
    margin-top: 25px;
  }
  .apinatorform-Heading {
    margin-top: 50px;
    color: white;
    font-size: 25px;
    display: flex;
    text-align: initial;
  }
  .apinatorform-x-tHeading {
    color: white;
    font-size: 25px;
    display: flex;
    text-align: initial;
    margin-top: 30px;
  }
  .getStarted-heading {
    text-align: initial;
    color: white;
    font-size: 30px;
    font-weight: 600;
    background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .apinatorProductContentMain {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  /* left product */
  .apinatorProductContentMainLeft {
    display: flex !important;
    column-gap: 13%;
    flex-direction: column-reverse !important;
  }

  .apinatorworkflow-Heading {
    margin-top: 45px;
    color: white;
    font-size: 24px;
    display: block;
    text-align: initial;
  }

  .apinatorProductContent-Workflow {
    color: white;
    font-size: 20px;
    width: 100%;
    line-height: 1.5;
    text-align: initial;
    margin-top: 0px;
  }

  .getstartedContent {
    text-align: initial;
    color: white;
    font-size: 20px;
    line-height: 1.5;
    width: 100%;
  }
  .productGetStarted-image {
    width: 100%;
    height: 60%;
    margin-top: 90px;
  }
}

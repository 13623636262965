.serviceHeading {
  color: #fff;
  margin-top: 40px;
  text-align: center;
  text-decoration: underline #2378e9;
  font-size: 35px;
}
.serviceCard {
  width: 75%;
  margin: auto;
}
.service-grid-main {
  margin-bottom: 75px !important;
}
.service-grid {
  margin-top: 50px !important;
}
.serviceCardText {
  background-color: #e4cfcf;
}
.serviceimages {
  width: 100%;
}
.serviceSubHeading {
  font-size: 25px;
  font-weight: 600;
}
.servicecontent {
  font-size: 20px;
}
.hrliness {
  background: #fcf113;
  width: 100%;
  height: 5px;
}
#iconbutton {
  font-size: 50px !important;
  color: #007bff;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .service-grid {
    margin-top: -175px !important;
  }
  .serviceSubHeading {
    font-size: 22px;
    font-weight: 600;
}
  .serviceCard {
    width: 85%;
    margin: auto;
    margin-top: 35px;
  }
}

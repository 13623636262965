.homepageHeading {
  color: #ffffff;
  font-size: 38px;
  width: 75%;
  display: block;
  text-align: initial;
  line-height: 1.5;
  font-weight: 700;
}

.homepagehrline {
  background: #2378e9;
  width: 110px;
  height: 3px;
}
.homepage-content-background-img {
  width: 100%;
  background-image: url("/public/images/Design.jpg");
  background-repeat: no-repeat;
  position: relative;
  background-size: 700px 550px;
  background-position-y: 30px;
  background-position-x: right;
}
.homepageSubHeading {
  color: #ffffff;
  font-size: 25px;
  width: 68%;
  text-align: initial;
  line-height: 2;
}

.homepageBtn {
  margin-bottom: 40px;
  height: 45px;
  background-color: #252525;
  color: white;
  font-family: Roboto;
  font-weight: 600;
  font-size: 20px;
  border-radius: 5px;
  border: 1px solid #007bff;
  display: block;
  width: 23%;
}

.homepageFirstimage {
  width: 100%;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .homepage-content-background-img {
    width: 100%;
    background-image: url("/public/images/Design.jpg");
    background-repeat: no-repeat;
    background-size: 185px 190px;
    background-position-y: 485px;
    background-position-x: right;
  }
  .homepageHeading {
    color: #ffffff;
    font-size: 25px;
    width: 100%;
    display: block;
    text-align: initial;
    line-height: 1.5;
    font-weight: 600;
  }
  .homepageSubHeading {
    color: #ffffff;
    font-size: 20px;
    width: 100%;
    text-align: initial;
    line-height: 1.5;
  }
  .homepageBtn {
    margin-bottom: 40px;
    height: 45px;
    background-color: #252525;
    color: white;
    font-family: Roboto;
    font-weight: 600;
    font-size: 20px;
    border-radius: 5px;
    border: 1px solid #007bff;
    display: block;
    width: 50%;
  }
}

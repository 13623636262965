.footer-main {
  margin-top: 50px;
}
.footer-content {
  color: white;
  width: 86%;
  margin-top: 2.5rem;
  line-height: 1.9;
  font-size: 20px;
  text-align: initial;
}

.footer-logo {
  text-align: initial;
}

.footer-links-main {
  margin-top: 5rem;
  justify-content: center;
  display: flex;
  font-size: 25px;
  color: #007bff;
}
.footer-links {
  color: white;
  font-size: 20px;
  line-height: 2;
}
.footer-about {
  justify-content: center;
  color: white;
  display: flex;
}
.footer-service {
  justify-content: center;
  color: white;
  display: flex;
}
.footer-product {
  justify-content: center;
  display: flex;
  color: white;
}
.footer-privacy {
  justify-content: center;
  display: flex;
  color: white;
}
.footer-sitemap {
  justify-content: center;
  display: flex;
  color: white;
}

.footer-contact-main {
  margin-top: 5rem;
  justify-content: center;
  display: flex;
  font-size: 25px;
  color: #007bff;
}

.footer-mail-id {
  font-size: 20px;
  color: white;
  display: flex;
  justify-content: center;
}
.footer-mail-underline {
  background: #2378e9;
  width: 60%;
  display: flex;
  margin: auto;
  height: 4px;
  margin-top: 14px;
}
.footer-address-main {
  display: flex;
  justify-content: center;
}
.footer-mobile {
  font-size: 20px;
  color: white;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 25px;
}
.footer-address {
  color: white;
  width: 70%;
  line-height: 2;
  font-size: 20px;
  text-align: initial;
}
.footer-icon-underline {
  background: #2378e9;
  width: 100%;
  height: 4px;
  margin-top: 14px;
}
.footer-copyright-text {
  text-align: initial;
  font-size: 18px;
  color: white;
}

.footer-icons-main {
  color: yellow;
  font-size: 25px;
  margin-top: 15px;
  margin-bottom: 10px;
  direction: rtl;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .footer-content {
    color: white;
    width: 100%;
    margin-top: 40px;
    line-height: 1.5;
    font-size: 20px;
    text-align: initial;
  }
  .footer-links-main {
    margin-top: 5rem;
    justify-content: center;
    display: flex;
    font-size: 25px;
    color: #007bff;
  }
  .footer-links {
    color: white;
    font-size: 20px;
    line-height: 2;
  }
  .footer-mail-underline {
    background: #2378e9;
    width: 70%;
    height: 4px;
    margin-top: 14px;
  }
  .footer-mobile {
    font-size: 20px;
    color: white;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .footer-address {
    color: white;
    width: 90%;
    line-height: 1.5;
    font-size: 20px;
    text-align: initial;
  }
  .footer-copyright-text {
    text-align: initial;
    font-size: 13px;
    color: white;
  }
  .footer-icons-main {
    color: yellow;
    font-size: 20px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 10px;
  }
}

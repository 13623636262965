.contactLandingSection {
  text-align: initial;
  color: white;
  font-size: 50px;
  font-weight: 600;
  background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 75% !important;
}
.contactvrlines {
  color: #2378e9;
  background: #2378e9;
  width: 10px;
  height: 200px;
  margin-bottom: 50px;
}
.contactSmallLine {
  color: #2378e9;
  background: #2378e9;
  width: 30px;
  margin-top: 40px;
  height: 5px;
}
.contactlandingSubSection {
  color: white;
  width: 88%;
  line-height: 2;
  margin-top: 15px;
  font-size: 25px;
  text-align: initial;
}

.checkbox-main {
  display: flex;
  color: white;
  margin-bottom: 40px;
  width: 75%;
  line-height: 2;
  font-size: 25px;
  display: flex;
  justify-content: center;
  margin: auto;
}

.textfield-main {
  text-align: center;
}

.textarea {
  background: #252525;
  color: white;
  border-color: #2378e9 !important;
}
textarea#message {
  color: white;
  background: #252525;
  border-color: #2378e9 !important;
}
::placeholder {
  color: white !important;
  opacity: 1;
}
.agree-text {
  font-size: 15px;
}
.contactSalesSubmit-btn {
  width: 20%;
  font-size: 20px !important;
  border-color: #2378e9 !important;
  color: white !important;
  display: flex;
}
.css-1ptx2yq-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 1px solid rgba(25, 118, 210, 1) !important;
}
.css-a88p61-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 1px solid rgba(25, 118, 210, 1) !important;
}
.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
  color: rgba(25, 118, 210, 1) !important ;
}
.css-ghsjzk-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 1px solid rgba(25, 118, 210, 1) !important;
}
.css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
  color: rgba(25, 118, 210, 1) !important ;
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  font-size: 20px !important;
  color: white !important;
}

.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
  color: white !important;
}
.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
  color: white !important;
  font-size: 20px !important;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .contactLandingSection {
    text-align: initial;
    color: white;
    font-size: 23px;
    font-weight: 600;
    background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100% !important;
  }
  .contactvrlines {
    color: #2378e9;
    background: #2378e9;
    width: 4px;
    height: 150px;
    margin-bottom: 50px;
  }
  .contactSmallLine {
    color: #2378e9;
    background: #2378e9;
    width: 20px;
    margin-top: 35px;
    height: 3px;
  }
  .contactlandingSubSection {
    color: white;
    width: 88%;
    line-height: 2;
    margin-top: 15px;
    font-size: 20px;
    text-align: center;
  }
  .textfield {
    width: 90% !important;
  }
  .textarea {
    width: 90% !important;
  }
  .textarea {
    background: #252525;
    color: white;
    border-color: #2378e9 !important;
  }
  textarea#message {
    color: white;
    background: #252525!;
  }
  .checkbox-main {
    display: flex;
    color: white;
    margin-bottom: 25px;
    width: 100%;
    line-height: 1.5;
    font-size: 20px;
  }
  .agree-text {
    font-size: 16px;
  }
  .contactSalesSubmit-btn {
    width: 40%;
    font-size: 15px !important;
    border-color: rgba(25, 118, 210, 1) !important;
    color: white !important;
  }
}

.privacy-heading {
  text-align: initial;
  color: white;
  font-size: 40px;
  font-weight: 600;
  background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.privacyvrlines {
  color: #2378e9;
  background: #2378e9;
  width: 10px;
  height: 110px;
}
.update-date {
  text-align: initial;
  color: white;
  font-size: 20px;
  font-weight: 550;
  background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 100%;
}
.privacy-landingSubSection {
  color: white;
  width: 88%;
  line-height: 2;
  margin-top: 15px;
  font-size: 25px;
  text-align: initial;
  margin-bottom: 20px;
}
.interpretation-heading-main {
  margin-bottom: 40px;
}
.horizontal-Line-left {
  border: 2px solid #2378e9;
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-block;
}
.interpretation-text-content-main {
  font-size: 35px;
  font-weight: 800;
  background-image: linear-gradient(to bottom right, #e4cfcf, #fcf113);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  width: 100%;
  align-content: center;
  justify-content: center;
}
.horizontal-Line-right {
  border: 2px solid #2378e9;
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-block;
}
.interpretation-Sub-heading {
  font-size: 30px;
  font-weight: 800;
  color: white;
  margin-top: 10px;
}
.interpretation-Sub-content {
  font-size: 25px;
  color: white;
  line-height: 2;
}
.defination-heading {
  font-size: 30px;
  font-weight: 800;
  color: white;
  margin-top: 10px;
}
.defination-Sub-heading {
  font-size: 25px;
  color: white;
  line-height: 2;
}
.defination-Sub-contents {
  font-size: 25px;
  color: white;
  line-height: 2;
  display: list-item;
  font-family: kepler std;
}
.personal-data-heading {
  font-size: 35px;
  font-weight: 800;
  background-image: linear-gradient(to bottom right, #e4cfcf, #fcf113);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 100%;
}
.personal-data-Sub-heading-main {
  font-size: 25px;
  font-weight: 600;
  color: white;
  line-height: 2;
}
.personal-data-heading-sub {
  font-size: 25px;
  font-weight: 600;
  color: white;
}
.personal-data-content {
  font-size: 25px;
  line-height: 2;
  color: white;
}
.usage-data-heading-main {
  font-size: 30px;
  font-weight: 600;
  color: white;
}
.usage-data-content {
  font-size: 25px;
  line-height: 2;
  color: white;
}
.tracking-technologies-heading {
  font-size: 30px;
  font-weight: 600;
  color: white;
}
.tracking-technologies-sub {
  font-size: 25px;
  line-height: 2;
  color: white;
}
.persnal-data-heading {
  font-size: 30px;
  font-weight: 600;
  color: white;
}
.persnal-data-sub-heading {
  font-size: 25px;
  color: white;
}
.retention-main-heading {
  font-size: 30px;
  font-weight: 600;
  color: white;
}
.retention-content {
  font-size: 25px;
  line-height: 2;
  color: white;
}
.transfer-main-heading {
  font-size: 30px;
  font-weight: 600;
  color: white;
}
.transfer-content {
  font-size: 25px;
  line-height: 2;
  color: white;
}
.delete-main-heading {
  font-size: 30px;
  font-weight: 600;
  color: white;
}
.delete-content {
  font-size: 25px;
  line-height: 2;
  color: white;
}
.disclosure-main-heading {
  font-size: 30px;
  font-weight: 600;
  color: white;
}
.disclosure-sub-heading {
  font-size: 25px;
  color: white;
}
.disclosure-content {
  font-size: 25px;
  line-height: 2;
  color: white;
}
.disclosure-sub-content {
  font-size: 25px;
  color: white;
}

.other-heading {
  font-size: 30px;
  font-weight: 600;
  color: white;
}
.other-sub-content-main {
  font-size: 25px;
  color: white;
}
.other-Sub-content {
  font-size: 25px;
  color: white;
  line-height: 2;
  display: list-item;
  font-family: kepler std;
}
.security-heading {
  font-size: 30px;
  font-weight: 600;
  color: white;
}
.security-content {
  font-size: 25px;
  color: white;
  line-height: 2;
}
.children-privacy-heading {
  font-size: 35px;
  font-weight: 800;
  background-image: linear-gradient(to bottom right, #e4cfcf, #fcf113);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 100%;
}
.children-content {
  font-size: 25px;
  color: white;
  line-height: 2;
}
.links-heading {
  font-size: 35px;
  font-weight: 800;
  background-image: linear-gradient(to bottom right, #e4cfcf, #fcf113);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 100%;
}
.links-content {
  font-size: 25px;
  color: white;
  line-height: 2;
}
.changes-privacy-heading {
  font-size: 35px;
  font-weight: 800;
  background-image: linear-gradient(to bottom right, #e4cfcf, #fcf113);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 100%;
}
.changes-privacy-content {
  font-size: 25px;
  color: white;
  line-height: 2;
}

.contact-Us-heading {
  font-size: 35px;
  font-weight: 800;
  background-image: linear-gradient(to bottom right, #e4cfcf, #fcf113);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 100%;
}
.contact-Us-sub-heading {
  font-size: 30px;
  color: white;
  line-height: 2;
}
.contact-Us-content {
  font-size: 25px;
  color: white;
  line-height: 2;
  display: list-item;
  font-family: kepler std;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .privacy-heading {
    text-align: initial;
    color: white;
    font-size: 35px;
    font-weight: 600;
    background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .privacyvrlines {
    color: #2378e9;
    background: #2378e9;
    width: 5px;
    height: 110px;
  }
  .update-date {
    text-align: initial;
    color: white;
    font-size: 19px;
    font-weight: 550;
    background-image: linear-gradient(to bottom right, #fcf113, #e4cfcf);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100%;
  }
  .privacy-landingSubSection {
    color: white;
    width: 100%;
    line-height: 1.5;
    margin-top: 15px;
    font-size: 20px;
    text-align: initial;
    margin-bottom: 20px;
  }
  .horizontal-Line-left {
    border: 2px solid #2378e9;
    width: 80%;
    align-items: center;
    justify-content: center;
    display: inline-block;
  }
  .interpretation-text-content-main {
    font-size: 23px;
    font-weight: 800;
    background-image: linear-gradient(to bottom right, #e4cfcf, #fcf113);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: flex;
    width: 100%;
    align-content: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .horizontal-Line-right {
    border: 2px solid #2378e9;
    width: 80%;
    align-items: center;
    justify-content: center;
    display: inline-block;
  }
  .interpretation-Sub-heading {
    font-size: 30px;
    font-weight: 800;
    color: white;
  }
  .interpretation-Sub-content {
    font-size: 20px;
    color: white;
    line-height: 1.5;
    width: 100%;
  }
  .defination-Sub-heading {
    font-size: 25px;
    color: white;
    line-height: 1.5;
  }
  .defination-Sub-contents {
    font-size: 20px;
    color: white;
    line-height: 1.5;
    display: list-item;
    font-family: kepler std;
  }
  .personal-data-heading {
    font-size: 32px;
    font-weight: 800;
    background-image: linear-gradient(to bottom right, #e4cfcf, #fcf113);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100%;
  }
  .personal-data-content {
    font-size: 20px;
    line-height: 1.5;
    color: white;
    width: 100%;
  }
  .usage-data-content {
    font-size: 20px;
    line-height: 1.5;
    color: white;
  }
  .usage-data-heading-main {
    font-size: 25px;
    font-weight: 600;
  }
  .tracking-technologies-heading {
    font-size: 25px;
    font-weight: 600;
    color: white;
  }
  .tracking-technologies-sub {
    font-size: 20px;
    line-height: 1.5;
    color: white;
    width: 100%;
  }
  .persnal-data-heading {
    font-size: 25px;
    font-weight: 600;
    color: white;
  }
  .persnal-data-sub-heading {
    font-size: 22px;
    color: white;
  }
  .retention-main-heading {
    font-size: 24px;
    font-weight: 600;
    color: white;
  }

  .retention-content {
    font-size: 20px;
    line-height: 2;
    color: white;
  }
  .transfer-main-heading {
    font-size: 25px;
    font-weight: 600;
    color: white;
  }
  .transfer-content {
    font-size: 20px;
    line-height: 1.5;
    color: white;
  }
  .delete-main-heading {
    font-size: 25px;
    font-weight: 600;
    color: white;
  }
  .delete-content {
    font-size: 20px;
    line-height: 1.5;
    color: white;
  }
  .disclosure-main-heading {
    font-size: 24px;
    font-weight: 600;
    color: white;
  }
  .disclosure-content {
    font-size: 20px;
    line-height: 1.5;
    color: white;
  }
  .other-heading {
    font-size: 25px;
    font-weight: 600;
    color: white;
  }
  .other-sub-content-main {
    font-size: 20px;
    line-height: 1.5;
    color: white;
  }
  .other-Sub-content {
    font-size: 20px;
    color: white;
    line-height: 1.5;
    display: list-item;
    font-family: kepler std;
  }
  .security-heading {
    font-size: 25px;
    font-weight: 600;
    color: white;
  }
  .security-content {
    font-size: 20px;
    color: white;
    line-height: 1.5;
  }
  .children-content {
    font-size: 20px;
    color: white;
    line-height: 1.5;
  }
  .links-content {
    font-size: 20px;
    color: white;
    line-height: 1.5;
  }
  .changes-privacy-content {
    font-size: 20px;
    color: white;
    line-height: 1.5;
  }
  .contact-Us-sub-heading {
    font-size: 20px;
    color: white;
    line-height: 1.5;
  }
  .contact-Us-content {
    font-size: 20px;
    color: white;
    line-height: 1.5;
    display: list-item;
    font-family: kepler std;
  }
}

.clientsCard {
  background-color: white;
  margin-top: 10px;
  width: 100%;
}
.clientsHeading {
  font-size: 35px;
  font-weight: 600;
  color: #000;
  text-align: center;
  text-decoration: underline #2378e9;
}

.philip {
  width: 100%;
}
.nasper {
  width: 100%;
}
.vodafone {
  width: 100%;
}
.ford {
  width: 100%;
}
.cisco {
  width: 100%;
}
.cisco2 {
  width: 100%;
}
.clientsbussinessoutcome-main {
  display: flex;
}
.clientsbussinessoutcome-heading {
  text-align: initial;
  color: white;
  font-size: 60px;
  font-weight: 600;
  background-image: linear-gradient(to bottom right, #e4cfcf, #fcf113);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 75%;
  margin-top: 50px;
  margin-bottom: 10px;
  line-height: 1.5;
}
.clientsimageone {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 25px;
}
.clientsimagesecond {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 25px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .clientsbussinessoutcome-heading {
    text-align: initial;
    color: white;
    font-size: 25px;
    font-weight: 600;
    background-image: linear-gradient(to bottom right, #e4cfcf, #fcf113);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 10px;
    line-height: 1.5;
  }
  .clientsimageone {
    width: 100%;
    height: -webkit-fill-available;
    margin-bottom: 10px;
    margin-top: 25px;
  }
  .clientsimagesecond {
    width: 100%;
    height: -webkit-fill-available;
    margin-bottom: 10px;
    margin-top: 25px;
  }
}

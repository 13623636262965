.aboutusHeading {
  color: white;
  display: flex;
  font-size: 30px;
}

.aboutushrline {
  background: #2378e9;
  width: 100px;
  height: 3px;
}

.aboutusSubHeading {
  color: white;
  width: 75%;
  font-size: 25px;
  text-align: initial;
  line-height: 2;
}

.aboutusBtn {
  margin-bottom: 30px;
  height: 45px;
  background-color: #252525;
  color: white;
  font-family: Roboto;
  font-weight: 600;
  font-size: 20px;
  border-radius: 5px;
  border: 1px solid #007bff;
  display: block;
  width: 26%;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .aboutusSubHeading {
    color: white;
    width: 100%;
    font-size: 21px;
    text-align: initial;
    line-height: 2;
  }
  .aboutushrline {
    background: #2378e9;
    width: 75px;
    height: 2px;
}
  .aboutusBtn {
    margin-bottom: 30px;
    height: 45px;
    background-color: #252525;
    color: white;
    font-family: Roboto;
    font-weight: 600;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid #007bff;
    display: block;
    width: 100%;
  }
}

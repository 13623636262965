.exploreproductSection-main {
  background: white;
  display: flex;
  height: 475px;
  flex-direction: row;
}

.exploreproductContent {
  background-image: linear-gradient(to bottom right, #1d22a3, #cd1228);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 60%;
  font-size: 35px;
  text-align: initial;
  padding: 30px;
}

.exploreproductBtn {
  height: 45px;
  background-color: white;
  color: #252525;
  font-family: Roboto;
  font-weight: 600;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid #007bff;
  display: block;
  width: 33%;
}

/* .exploreproductimageone {
  width: 100%;
  height: 300px;
  margin-top: 40px;
} */

.exploreproductimagetwo {
  width: 40%;
  height: 450px;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .exploreproductContent {
    background-image: linear-gradient(to bottom right, #1d22a3, #cd1228);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100%;
    font-size: 17px;
    text-align: initial;
    padding: 0px;
  }
  .exploreproductimagetwo {
    width: 40%;
    height: 275px;
}
.exploreproductSection-main {
  background: white;
  display: flex;
  height: 375px;
  flex-direction: row;
}
.exploreproductBtn {
  height: 40px;
  background-color: white;
  color: #252525;
  font-family: Roboto;
  font-weight: 550;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid #007bff;
  display: block;
  width: 100%;
  text-align: initial;
}
}
